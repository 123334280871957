import { useState } from "react";
import { useEditorContext } from "./EditorContext";

const JSONArea = () => {
    const { document } = useEditorContext();
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="debug-panel">
            <button onClick={toggleVisibility}>
                {isVisible ? 'Hide JSON' : 'Show JSON'}
            </button>
            {isVisible && <pre>{JSON.stringify(document, null, 2)}</pre>}
        </div>
    );
};

export default JSONArea;