import './Editor.css';
import { useEffect } from 'react';
import EditArea from './EditArea';
import DragArea from './DragArea';
import { CompnentDefinitions, ComponentDef, DocumentData } from '../types';
import JSONArea from './JSON';
import { assignGuids } from './DocumentDataHelper';
import { useEditorContext } from './EditorContext';

const Editor = () => {
    const { document, setDocument, componentDefs, setComponentDefs, dragAreaGroups, setDragAreaGroups } = useEditorContext();

    const fetchComponents = async () => {
        try {
            const response = await fetch('/api/components.json');
            const data = await response.json();
            const components = data as CompnentDefinitions;
            setComponentDefs(components.componentDefs);
            setDragAreaGroups(components.dragAreaGroups);
        } catch (error) {
            console.error('Error fetching components:', error);
        }
    };

    const fetchDocument = async (path: string) => {
        try {
            const response = await fetch(path);
            const data = await response.json();
            const doc = data as DocumentData;
            assignGuids(doc);
            setDocument(doc);
        } catch (error) {
            console.error('Error fetching components:', error);
        }
    };

    useEffect(() => {

        fetchComponents();
        fetchDocument('/api/sampledata/empty.json');
    }, []);

    return (
        <>
            <div className="editor">
                <div className="left-panel">
                    {document ? <EditArea /> : <div>Loading...</div>}
                </div>
                <div className="right-panel">
                    <div className="buttons">
                        <button onClick={() => fetchDocument('/api/sampledata/empty.json')}>Clear</button>
                        <button onClick={() => fetchDocument('/api/sampledata/template1.json')}>T1</button>
                        <button onClick={() => fetchDocument('/api/sampledata/template2.json')}>T2</button>
                        <button onClick={() => fetchDocument('/api/sampledata/instruction.json')}>Instruction</button>
                        <button onClick={() => fetchDocument('/api/sampledata/zitate.json')}>Einstein</button>
                        <button onClick={() => fetchDocument('/api/sampledata/page.json')}>Test</button>
                    </div>
                    {componentDefs ? <DragArea /> : <div>Loading...</div>}
                </div>
                {document ? <JSONArea /> : <div>Loading...</div>}
            </div>
        </>
    );
}

export default Editor;
