// fix for Firefox to set a selection inside a contenteditable element
export function createSelectionFromPoint(startX, startY) {
    var doc = document;
    var start, range = null;
    if (typeof doc.caretPositionFromPoint != "undefined") {
        start = doc.caretPositionFromPoint(startX, startY);
        range = doc.createRange();
        range.setStart(start.offsetNode, start.offset);
        range.collapse(true);
    } else if (typeof doc.caretRangeFromPoint != "undefined") {
        start = doc.caretRangeFromPoint(startX, startY);
        range = doc.createRange();
        range.setStart(start.startContainer, start.startOffset);
        range.collapse(true);
    }
    if (range !== null && typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof doc.body.createTextRange != "undefined") {
        range = doc.body.createTextRange();
        range.moveToPoint(startX, startY);
        range.select();
        range.collapse(true);
    }
}
