import './App.css';
import { EditorProvider } from './components/EditorContext';
import Editor from './components/Editor';

const App = () => {
  return (
    <div className="app">
      <EditorProvider>
        <Editor />
      </EditorProvider>
    </div>
  );
};
export default App;
