import React, { createContext, useContext, ReactNode, useState } from 'react';
import { ComponentData, ComponentDef, DocumentData, DragAreaGroup } from '../types';

// Define the shape of the context.
type EditorContextType = {
  document: DocumentData;
  setDocument: (document: DocumentData) => void;
  updateDocument(): void;
  componentDefs: ComponentDef[];
  setComponentDefs: (componentDefs: ComponentDef[]) => void;
  dragAreaGroups: DragAreaGroup[];
  setDragAreaGroups: (dragAreaGroups: DragAreaGroup[]) => void;
  dragComponentName?: string;
  setDragComponentName: (componentName?: string) => void;
};

// Create the context with default values.
const EditorContext = createContext<EditorContextType | undefined>(undefined);

// Create a provider component that takes children and the context data as props.
type EditProviderProps = {
  children: ReactNode;
};

export const EditorProvider: React.FC<EditProviderProps> = ({ children }) => {
  const [document, setDocument] = useState<DocumentData>({ title: '', components: [] });
  const [componentDefs, setComponentDefs] = useState<ComponentDef[]>([]);
  const [dragAreaGroups, setDragAreaGroups] = useState<DragAreaGroup[]>([]);
  const [dragComponentName, setDragComponentName] = useState<string | undefined>();

  function updateDocument() {
    setDocument({ ...document });
  }

  return (
    <EditorContext.Provider value={{ document, setDocument, updateDocument, componentDefs, setComponentDefs, dragAreaGroups, setDragAreaGroups, dragComponentName, setDragComponentName }}>
      {children}
    </EditorContext.Provider>
  );
};

// Custom hook for easy consumption of the context in other components.
export const useEditorContext = (): EditorContextType => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditContext must be used within an EditProvider');
  }
  return context;
};
