import { ComponentDef } from "../types";

export function setDragComponentDef(event: React.DragEvent<HTMLElement>, componentDef: ComponentDef): void {
    event.dataTransfer.effectAllowed = 'copy';
    event.dataTransfer.dropEffect = 'copy';
    setDragImage(event, componentDef.title);

    const component = {
        guid: crypto.randomUUID(),
        component: componentDef.name.toLowerCase(),
        props: {} as Record<string, any>
    };


    if (componentDef.props) {
        componentDef.props.forEach(prop => {
            if (prop.type === "group") {
                if (componentDef.name === "ul" || componentDef.name === "ol") {
                    (component.props as Record<string, any>)[prop.name] = [{ component: "li", props: { content: "" } }];
                }
                else {
                    (component.props as Record<string, any>)[prop.name] = [{ component: "placeholder", props: {} }];
                }
            } else {
                (component.props as Record<string, any>)[prop.name] = prop.default || "";
            }
        });
    }

    event.dataTransfer.setData("application/json", JSON.stringify(component));
}

export function setDragImage(event: React.DragEvent, title: string): void {
    const copy = document.createElement('div');
    copy.innerText = title;
    copy.classList.add('drag-image');
    document.body.appendChild(copy);

    event.dataTransfer.setDragImage(copy, 0, 0);

    setTimeout(() => {
        if (document.body.contains(copy)) {
            document.body.removeChild(copy);
        }
    }, 1);
}

export function removeDropClasses() {
    const editArea = document.querySelector('.edit-area');
    if (editArea) {
        editArea.querySelectorAll('.drop-top, .drop-bottom, .drop-onto').forEach(element => {
            element.classList.remove('drop-top', 'drop-bottom', 'drop-onto', 'forbidden', 'allowed');
        });
    }
}

