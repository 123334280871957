import { DocumentData, ComponentData } from "../types";

export function assignGuids(document: DocumentData, override: boolean = false): DocumentData {
    document.components.forEach((component) => {
        assignGuidToCompoment(component, override);
    });
    return document;
}

export function assignGuidToCompoment(component: ComponentData, override: boolean = false) {
    if (!component.guid || override) {
        component.guid = crypto.randomUUID();
    }
    for (const key in component.props) {
        const value = component.props[key];
        if (Array.isArray(value)) {
            (value as ComponentData[]).forEach((child) => {
                assignGuidToCompoment(child, override);
            });
        }
    }
}

export enum InsertPostion {
    Before = 0,
    After = 1,
    Onto = 99
}

export function removeComponent(guid: string, document: DocumentData, updateDocument?: () => void) {
    removeComponentInner(guid, document.components, updateDocument);
}

function removeComponentInner(guid: string, components: ComponentData[], updateDocument?: () => void) {
    for (let i = 0; i < components.length; i++) {
        const component = components[i];
        if (component) {
            if (component.guid === guid) {

                if(components.length === 1) {
                    const placeholder: ComponentData = { component: "placeholder", guid: crypto.randomUUID(), props: {} };
                    components.splice(i, 1, placeholder);
                }
                else {
                    components.splice(i, 1);
                }
                if(updateDocument) {
                    updateDocument();
                }

                return;
            }
            if (component.props) {
                for (const key in component.props) {
                    const value = component.props[key];
                    if (Array.isArray(value)) {
                        removeComponentInner(guid, value as ComponentData[], updateDocument);
                    }
                }
            }
        }
    }
}

export function insertComponent(insertAtGuid: string, insertAtPosition: InsertPostion, newComponent: ComponentData, document: DocumentData, updateDocument?: () => void) {
    insertComponentInner(insertAtGuid, insertAtPosition, document.components, newComponent, updateDocument);
}

function insertComponentInner(insertAtGuid: string, insertAtPosition: InsertPostion, components: ComponentData[], newComponent: ComponentData, updateDocument?: () => void) {
    for (let i = 0; i < components.length; i++) {
        const component = components[i];
        if (component) {
            if (component.guid === insertAtGuid) {
                assignGuidToCompoment(newComponent);
                if(insertAtPosition === InsertPostion.Before || insertAtPosition === InsertPostion.After) {
                    components.splice(i + insertAtPosition, 0, newComponent);
                }
                else {
                    components[i] = newComponent;
                }
                if(updateDocument) {
                    updateDocument();
                }
                return;
            }
            if (component.props) {
                for (const key in component.props) {
                    const value = component.props[key];
                    if (Array.isArray(value)) {
                        insertComponentInner(insertAtGuid, insertAtPosition, value as ComponentData[], newComponent, updateDocument);
                    }
                }
            }
        }
    }
}

export function findComponent(guid: string, document: DocumentData): ComponentData | null {
    return findComponentInner(guid, document.components);
}

function findComponentInner(guid: string, components: ComponentData[]): ComponentData | null {
    for (const component of components) {
        if (component.guid === guid) {
            return component;
        }
        if (component.props) {
            for (const key in component.props) {
                const value = component.props[key];
                if (Array.isArray(value)) {
                    const found = findComponentInner(guid, value as ComponentData[]);
                    if (found) {
                        return found;
                    }
                }
            }
        }
    }
    return null;
}

export function findComponentParent(guid: string, document: DocumentData): ComponentData[] | null {
    return findComponentParentInner(guid, document.components);
}

function findComponentParentInner(guid: string, components: ComponentData[]): ComponentData[] | null {
    for (const component of components) {
        if (component.guid === guid) {
            return components;
        }
        if (component.props) {
            for (const key in component.props) {
                const value = component.props[key];
                if (Array.isArray(value)) {
                    const parent = findComponentParentInner(guid, value as ComponentData[]);
                    if (parent) {
                        return parent;
                    }
                }
            }
        }
    }
    return null;
}

export function findNextSiblingContainer(guid: string, document: DocumentData): ComponentData | null {
    const parent = findComponentParent(guid, document);
    if (parent) {
        for (let i = 0; i < parent.length; i++) {
            if (parent[i].guid === guid && i < parent.length - 1) {
                return parent[i + 1];
            }
        }
    }
    return null;
}

export function findPreviousSiblingContainer(guid: string, document: DocumentData): ComponentData | null {
    const parent = findComponentParent(guid, document);
    if (parent) {
        for (let i = 0; i < parent.length; i++) {
            if (parent[i].guid === guid && i > 0) {
                return parent[i - 1];
            }
        }
    }
    return null;
}
