import React from "react";
import { ComponentData, ComponentDef, DocumentData } from "../types";
import { EditAreaTag } from "./EditAreaTag";
import { useEditorContext } from "./EditorContext";
import { EditAreaEditable } from "./EditAreaEditable";
import { assignGuidToCompoment, insertComponent, InsertPostion, removeComponent } from "./DocumentDataHelper";
import { removeDropClasses, setDragImage } from "./DragDrop";

type EditAreaComponentProps = {
    component: ComponentData;
    allowedGroupChildren?: string[];
}

export const EditAreaComponent: React.FC<EditAreaComponentProps> = ({ component, allowedGroupChildren }) => {
    const { document, updateDocument, componentDefs, dragComponentName, setDragComponentName } = useEditorContext();
    const componentDef =
        componentDefs.find((componentDef) => componentDef.name === component.component) ||
        { name: 'placeholder', title: "Placeholder", template: '<div class="placeholder"></div>', props: [] };

    const template = window.document.createElement('div');
    template.innerHTML = componentDef.template;

    return (
        <>
            {Array.from(template.children).map((child) => {
                return <div data-component={componentDef.name} data-allowed-children={allowedGroupChildren} data-guid={component.guid} draggable
                    onDragStart={(event: React.DragEvent) => {
                        dragstartEventHandler(event, component, componentDef);
                        setDragComponentName(componentDef.name);
                    }}
                    onDragOver={(event: React.DragEvent) => dragoverEventHandler(event, dragComponentName)}
                    onDrop={(event: React.DragEvent) => {
                        dropEventHandler(event, component.guid, document, updateDocument);
                        setDragComponentName(undefined);
                    }}
                    onDragEnd={(event: React.DragEvent) => {
                        dragEndEventHandler(event);
                        setDragComponentName(undefined);
                    }}
                >
                    {child.hasAttribute("contenteditable") ?
                        <EditAreaEditable componentDef={componentDef!} component={component} templateElement={child} /> :
                        <EditAreaTag componentDef={componentDef!} component={component} templateElement={child} />}
                </div>
            })}
        </>
    );
}

enum DropPoint {
    Invalid = -1,
    Top = 0,
    Bottom = 1,
    Onto = 99
}

function dragstartEventHandler(event: React.DragEvent, component: ComponentData, componentDef: ComponentDef) {
    event.stopPropagation();

    event.dataTransfer.effectAllowed = 'copyMove';
    event.dataTransfer.dropEffect = 'move';
    setDragImage(event, componentDef.title);
    event.dataTransfer.setData("application/json", JSON.stringify(component));
}

function dragoverEventHandler(event: React.DragEvent, dragComponentName?: string) {
    event.preventDefault();
    event.stopPropagation();

    if (!dragComponentName) {
        event.dataTransfer.dropEffect = 'none';
        return;
    }

    switch (event.dataTransfer.effectAllowed) {
        case 'copyMove':
            event.dataTransfer.dropEffect = event.ctrlKey ? 'copy' : 'move';
            break;
        case 'move':
            event.dataTransfer.dropEffect = 'move';
            break;
        case 'copy':
            event.dataTransfer.dropEffect = 'copy';
            break;
        default:
            event.dataTransfer.dropEffect = 'none';
    }

    const bestDropTarget = getBestDropTargetElement(event, dragComponentName);

    const dropPoint = getDropPoint(bestDropTarget, event);

    removeDropClasses();

    if (dropPoint === DropPoint.Top) {
        (bestDropTarget as HTMLElement).classList.add('drop-top');
    }
    else if (dropPoint === DropPoint.Bottom) {
        (bestDropTarget as HTMLElement).classList.add('drop-bottom');
    }
    else if (dropPoint === DropPoint.Onto) {
        (bestDropTarget as HTMLElement).classList.add('drop-onto');
    }

    const allowedChildren = bestDropTarget.getAttribute('data-allowed-children')?.split(',') || [];
    if (allowedChildren.length > 0 && !allowedChildren.includes(dragComponentName)) {
        event.dataTransfer.dropEffect = 'none';
        (bestDropTarget as HTMLElement).classList.add('forbidden');
    }
    else {
        (bestDropTarget as HTMLElement).classList.remove('allowed');
    }
}

function dropEventHandler(event: React.DragEvent, guid: string, document: DocumentData, updateDocument: () => void) {
    event.preventDefault();
    event.stopPropagation();

    removeDropClasses();

    const dragComponent = getDropData(event);
    if (dragComponent === null) {
        return;
    }

    const bestDropTarget = getBestDropTargetElement(event, dragComponent.component);
    if (bestDropTarget === null) {
        return;
    }

    const dropPoint = getDropPoint(bestDropTarget as Element, event);

    if (dragComponent && guid && dropPoint !== DropPoint.Invalid) {

        let dropComponent: ComponentData;
        if (!event.ctrlKey && (event.dataTransfer.effectAllowed === 'move' || event.dataTransfer.effectAllowed === 'copyMove')) {
            // Move
            removeComponent(dragComponent.guid, document);
            dropComponent = dragComponent;
        }
        else {
            dropComponent = { ...dragComponent};
            assignGuidToCompoment(dragComponent, true);
        }

        if (dropPoint === DropPoint.Top) {
            insertComponent(guid, InsertPostion.Before, dropComponent, document, updateDocument);
        }
        else if (dropPoint === DropPoint.Bottom) {
            insertComponent(guid, InsertPostion.After, dropComponent, document, updateDocument);
        }
        else if (dropPoint === DropPoint.Onto) {
            insertComponent(guid, InsertPostion.Onto, dropComponent, document, updateDocument);
        }
    }
}

function dragEndEventHandler(event: React.DragEvent) {
    removeDropClasses();
}

function getDropData(event: React.DragEvent): ComponentData | null {
    const dropJson = event.dataTransfer?.getData("application/json");
    if (dropJson) {
        const compoent: ComponentData = JSON.parse(dropJson);
        return compoent
    }
    return null;
}

function getDropTargetElementList(element: HTMLElement): HTMLElement[] {
    const elements: HTMLElement[] = [];
    let currentElement: HTMLElement | null = element;

    while (currentElement && !currentElement.classList.contains('edit-area')) {
        if (currentElement.hasAttribute("data-component")) {
            elements.push(currentElement);
        }
        currentElement = currentElement.parentElement;
    }
    return elements;
}

function getBestDropTargetElement(event: React.DragEvent, dragComponentName: string): HTMLElement {
    const targetElement = event.currentTarget as HTMLElement;
    if (targetElement.getAttribute('data-component') === "placeholder") {
        return targetElement;
    }
    const dropTargets = getDropTargetElementList(targetElement);

    let bestDropTarget: HTMLElement = targetElement;
    let bestDistance = Number.MAX_VALUE;

    dropTargets.forEach(dropTarget => {
        const rect = dropTarget.getBoundingClientRect();
        const distanceX = Math.abs(event.clientX - (rect.left + rect.width / 2)) / rect.width;
        //const distanceY = Math.abs(event.clientY - (rect.top + rect.height / 2)) / rect.height;
        //const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
        const distance = distanceX;
        if (distance < bestDistance) {
            bestDropTarget = dropTarget;
            bestDistance = distance;
        }
    });

    return bestDropTarget;
}

const getDropPoint = (targetElement: Element, event: React.DragEvent): DropPoint => {
    if (targetElement.getAttribute('data-component') === "placeholder") {
        return DropPoint.Onto;
    }

    const rect = targetElement.getBoundingClientRect();
    const y = event.clientY - rect.top;

    if (y < rect.height / 2) {
        return DropPoint.Top;
    }
    if (y > rect.height / 2) {
        return DropPoint.Bottom;
    }

    return DropPoint.Invalid;
}
