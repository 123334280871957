import './EditArea.css';
import React from 'react';
import { useEditorContext } from './EditorContext';
import { EditAreaComponent } from './EditAreaComponent';

type EditAreaProps = {
}

const EditArea: React.FC<EditAreaProps> = () => {
    const { componentDefs, document } = useEditorContext();
    const allowedGroupChildren = componentDefs?.filter((componentDef) => componentDef.name !== 'li').map((componentDef) => componentDef.name) || [];

    return (
        <>
            {document && componentDefs ? (
                <div className='edit-area'>
                    <link rel='stylesheet' href='/api/components.css ' />
                    {document.components.map((component) => (
                        <EditAreaComponent key={component.guid} component={component} allowedGroupChildren={allowedGroupChildren} />
                    ))}
                </div>
            ) : (
                <div className='edit-area'>Loading...</div>
            )}
        </>
    );
};

export default EditArea;