import React, { useEffect, useRef } from "react";
import { ComponentData, ComponentDef, ComponentPropDef } from "../types";
import { useEditorContext } from "./EditorContext";
import { findPreviousSiblingContainer, insertComponent, InsertPostion, removeComponent } from "./DocumentDataHelper";
import { createSelectionFromPoint } from "./JavascriptHelper";
import { isFirefox } from "./Helper";

type EditAreaEditableProps = {
    componentDef: ComponentDef;
    component: ComponentData
    templateElement: Element,
};

export const EditAreaEditable: React.FC<EditAreaEditableProps> = ({ componentDef, component, templateElement }) => {
    const { document, updateDocument } = useEditorContext();
    const elementRef = useRef<HTMLDivElement>(null);
    const componentPropDef = getPropertyDefFromNode(componentDef, templateElement);

    const attributes = Array.from(templateElement.attributes).reduce((acc, attr) => {
        // Replace {name} with actual values
        attr.value = attr.value.replace(/{(.*?)}/g, (match, propName) => {
            const propDef = componentDef.props.find((prop) => prop.name === propName);
            if (propDef) {
                const value = component.props[propDef.name];
                return value;
            }
            return match;
        });
        acc[attr.name] = attr.value;
        return acc;
    }, {} as Record<string, any>);

    attributes['data-placeholder'] = componentPropDef?.placeholder
    attributes.onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => keyDownEventHandler(event);
    attributes.onInput = (event: React.KeyboardEvent<HTMLElement>) => inputEventHandler(event);
    attributes.onPaste = (event: React.ClipboardEvent<HTMLElement>) => pastEventHandler(event);

    if (isFirefox()) {
        attributes.onMouseDown = (event: React.MouseEvent<HTMLElement>) => mouseDownEventHandler(event);
    }

    // Set the initial value of the contenteditable element
    // This is only done once when the component is mounted
    // React it self will not update this innerHTML - we want to control it
    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.innerHTML = component.props[componentPropDef!.name];
        }
    }, []);

    function mouseDownEventHandler(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        createSelectionFromPoint(event.clientX, event.clientY);
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        if (range && selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    // function to update the component data and the document
    function inputEventHandler(event: React.KeyboardEvent<HTMLElement>) {
        // fix empty contenteditable to show the data-placeholder
        if (event.currentTarget.innerHTML === "<br>") {
            event.currentTarget.innerHTML = "";
        }

        // Update the component data
        updateDocumentContent();
    }

    function pastEventHandler(event: React.ClipboardEvent<HTMLElement>) {
        event.preventDefault();

        const html = event.clipboardData?.getData('text/html') ?? event.clipboardData?.getData('text/plain') ?? "";

        const nodes = buildAllowedHtml(html, componentPropDef!);

        if (nodes && nodes.length > 0) {
            const selection = window.getSelection();
            if (!selection?.rangeCount) return;
            selection.deleteFromDocument();

            const range = selection.getRangeAt(0);
            for (let i = nodes.length - 1; i >= 0; i--) {
                range.insertNode(nodes[i]);
            }

            component.props[componentPropDef!.name] = event.currentTarget.innerHTML;
            updateDocument();
        }
    }

    function keyDownEventHandler(event: React.KeyboardEvent<HTMLElement>) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (event.shiftKey) {
                console.log("Insert br on Shift + Enter pressed");
                const selection = window.getSelection();
                if (selection && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    range.deleteContents();
                    const br = window.document.createElement("br");
                    range.insertNode(br);

                    // Move the cursor after the <br> element
                    range.setStartAfter(br);
                    range.setEndAfter(br);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
                return;
            }

            if (componentDef.name === "p" || componentDef.name === "li") {
                const selection = window.getSelection();
                if (selection && selection.rangeCount > 0) {
                    const range = selection?.getRangeAt(0);

                    const startContainer = range.startContainer;
                    const endContainer = range.endContainer;

                    const precedingContent = getPrecedingContent(elementRef.current!, endContainer, range.endOffset);
                    const nextContent = getNextContent(elementRef.current!, startContainer, range.startOffset);

                    event.currentTarget.innerHTML = nextContent.innerHTML;

                    const newComponent: ComponentData = { "component": component.component, "props": { "content": precedingContent.innerHTML }, guid: crypto.randomUUID() };
                    insertComponent(component.guid, InsertPostion.Before, newComponent, document);
                    updateDocumentContent();
                }
            }

            updateDocumentContent();
            return;
        }

        // Handle Backspace key
        if (event.key === "Backspace") {
            console.log("Backspace key pressed", event.currentTarget.innerHTML);

            if (component.component === "p" || component.component === "li") {
                const selection = window.getSelection();
                if (selection && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    // if we are at the start of the range
                    if (range.startOffset === 0) {
                        event.preventDefault();
                        if (elementRef.current?.innerHTML.length === 0) {
                            removeComponent(component.guid, document);
                            updateDocument();
                            return;
                        }

                        const prevComponent = findPreviousSiblingContainer(component.guid, document);
                        if (prevComponent && prevComponent.component === component.component) {

                            // set new content
                            elementRef.current!.innerHTML = prevComponent.props.content;

                            const tmp = window.document.createElement("div");
                            tmp.innerHTML = component.props.content;
                            tmp.childNodes.forEach(node => { elementRef.current?.appendChild(node) });

                            range.setStart(elementRef.current!.firstChild!, elementRef.current!.firstChild!.textContent!.length);
                            range.collapse(true);

                            // remove the previous component
                            removeComponent(prevComponent.guid, document);

                            // update the document
                            updateDocumentContent();
                        }

                    }
                }
            }
            return;
        }

        // Handle Delete key
        if (event.key === "Delete") {
            console.log("Delete key pressed");
            return;
        }

        // Handle ctrl+b key
        if (event.ctrlKey && event.key === "b") {
            console.log("Bold key pressed");
            event.preventDefault();
            if (componentPropDef!.children) {
                if (componentPropDef!.children.includes("b")) {
                    toggleTag('b');
                    return;
                }
                if (componentPropDef!.children.includes("strong")) {
                    toggleTag('strong');
                    return;
                }
            }
            return;
        }

        // Handle ctrl+i key
        if (event.ctrlKey && event.key === "i") {
            console.log("Italic key pressed");
            event.preventDefault();
            if (componentPropDef!.children) {
                if (componentPropDef!.children.includes("i")) {
                    toggleTag('i');
                    return;
                }
                if (componentPropDef!.children.includes("em")) {
                    toggleTag('em');
                    return;
                }
            }
            return;
        }

        // Handle ctrl+, key (subscript)
        if (event.ctrlKey && event.key === ",") {
            console.log("Subscript key pressed");
            event.preventDefault();
            if (componentPropDef!.children) {
                if (componentPropDef!.children.includes("sub")) {
                    toggleTag('sub');
                    return;
                }
            }
            return;
        }

        // Handle ctrl+. key (superscript)
        if (event.ctrlKey && event.key === ".") {
            console.log("Superscript key pressed");
            event.preventDefault();
            if (componentPropDef!.children) {
                if (componentPropDef!.children.includes("sup")) {
                    toggleTag('sup');
                    return;
                }
            }
            return;
        }
        return;
    }

    function updateDocumentContent() {
        if (component.props[componentPropDef!.name] !== elementRef.current?.innerHTML) {
            component.props[componentPropDef!.name] = elementRef.current?.innerHTML;
            updateDocument();
        }
    }

    function toggleTag(tag: string) {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) {
            return;
        }

        const range = selection.getRangeAt(0);
        let isAlreadyTagged = false;

        // Check if the tag is already applied to any of the parent nodes
        let currentNode: Node | null = range.startContainer;
        while (currentNode && currentNode !== elementRef.current) {
            if (currentNode instanceof HTMLElement && currentNode.tagName.toLowerCase() === tag) {
                isAlreadyTagged = true;
                break;
            }
            currentNode = currentNode.parentNode;
        }

        if (isAlreadyTagged) {
            // Remove the tag
            unwrapTag(range, tag);
        } else {
            // Apply the tag
            wrapTag(range, tag);
        }
        updateDocumentContent();
    }

    function wrapTag(range: Range, tag: string) {
        const newElement = window.document.createElement(tag);
        newElement.appendChild(range.extractContents());
        range.insertNode(newElement);

        // Restore the original range
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            const newRange = window.document.createRange();
            newRange.setStart(newElement.firstChild!, 0);
            newRange.setEnd(newElement.lastChild!, newElement.lastChild!.textContent?.length ?? 0);
            selection.addRange(newRange);
        }
    }

    function unwrapTag(range: Range, tag: string) {
        const parentElement = range.startContainer.parentElement;
        if (parentElement && parentElement.tagName.toLowerCase() === tag) {
            const fragment = window.document.createDocumentFragment();
            const startOffset = range.startOffset;
            const endOffset = range.endOffset;
            const startContainer = range.startContainer;
            const endContainer = range.endContainer;

            while (parentElement.firstChild) {
                fragment.appendChild(parentElement.firstChild);
            }
            parentElement.parentNode?.replaceChild(fragment, parentElement);

            // Restore the original range
            const newRange = window.document.createRange();
            newRange.setStart(startContainer, startOffset);
            newRange.setEnd(endContainer, endOffset);
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(newRange);
            }
        }
    }


    return React.createElement(
        templateElement.tagName.toLowerCase() as keyof JSX.IntrinsicElements,
        {
            ...attributes,
            ref: elementRef
        }
    );
};

function getPropertyDefFromNode(componentDef: ComponentDef, node: Node): ComponentPropDef | null {

    const text = node.textContent!.trim();
    if (!text) return null;

    const propName = text.slice(1, -1);
    const propDef = componentDef.props.find((prop) => prop.name === propName);
    if (!propDef) return null;

    return propDef;
}

function buildAllowedHtml(html: string, componentPropDef: ComponentPropDef): ChildNode[] | null {
    const allowedTags = componentPropDef.children ?? [];
    const tmp = document.createElement("div");
    tmp.innerHTML = html;

    if (allowedTags.includes("strong") && !allowedTags.includes("b")) {
        replaceTag(tmp, "b", "strong");
    }

    if (allowedTags.includes("b") && !allowedTags.includes("strong")) {
        replaceTag(tmp, "strong", "b");
    }

    if (allowedTags.includes("em") && !allowedTags.includes("i")) {
        replaceTag(tmp, "i", "em");
    }

    if (allowedTags.includes("i") && !allowedTags.includes("em")) {
        replaceTag(tmp, "em", "i");
    }

    removeTags(tmp, allowedTags);

    return Array.from(tmp.childNodes);

    function removeTags(root: HTMLElement, allowedTags: string[]): void {
        const allElements = Array.from(root.getElementsByTagName('*'));
        allElements.forEach(element => {
            if (!allowedTags.includes(element.tagName.toLowerCase())) {
                while (element.firstChild) {
                    element.parentNode?.insertBefore(element.firstChild, element);
                }
                element.parentNode?.removeChild(element);
            }
        });
    }

    function replaceTag(root: HTMLElement, tag: string, newTag: string): void {
        const elements = root.getElementsByTagName(tag);
        const elementsArray = Array.from(elements); // Um eine statische Liste zu erzeugen, um die Sammlung zu modifizieren

        elementsArray.forEach((element) => {
            const newElement = document.createElement(newTag);

            while (element.firstChild) {
                newElement.appendChild(element.firstChild);
            }

            element.parentNode?.replaceChild(newElement, element);
        });
    }

}

function getNextContent(root: HTMLElement, container: Node, index: number): HTMLElement {
    const clonedRoot = root.cloneNode(true) as HTMLElement;

    let clonedContainer: Node | null = findCorrespondingNode(root, clonedRoot, container);
    if (!clonedContainer) {
        throw new Error("Container node not found in cloned structure.");
    }

    clonedContainer.textContent = clonedContainer.textContent!.slice(index);

    deletePrecedingNodes(clonedContainer);

    while (clonedContainer && clonedContainer !== clonedRoot) {
        if (clonedContainer.parentNode) {
            deletePrecedingNodes(clonedContainer);
            clonedContainer = clonedContainer.parentNode;
        } else {
            break;
        }
    }

    return clonedRoot;
}

function getPrecedingContent(root: HTMLElement, container: Node, index: number): HTMLElement {
    const clonedRoot = root.cloneNode(true) as HTMLElement;

    let clonedContainer: Node | null = findCorrespondingNode(root, clonedRoot, container);
    if (!clonedContainer) {
        throw new Error("Container node not found in cloned structure.");
    }

    clonedContainer.textContent = clonedContainer.textContent!.slice(0, index);

    deleteNextNodes(clonedContainer);

    while (clonedContainer && clonedContainer !== clonedRoot) {
        if (clonedContainer.parentNode) {
            deleteNextNodes(clonedContainer);
            clonedContainer = clonedContainer.parentNode;
        } else {
            break;
        }
    }

    return clonedRoot;
}

function deletePrecedingNodes(node: Node) {
    let sibling = node.previousSibling;
    while (sibling) {
        const prevSibling = sibling;
        sibling = sibling.previousSibling;
        prevSibling.parentNode?.removeChild(prevSibling);
    }
}

function deleteNextNodes(node: Node) {
    let sibling = node.nextSibling;
    while (sibling) {
        const nextSibling = sibling;
        sibling = sibling.nextSibling;
        nextSibling.parentNode?.removeChild(nextSibling);
    }
}

// Helper function: Finds the corresponding node in the cloned tree
function findCorrespondingNode(originalRoot: Node, clonedRoot: Node, targetNode: Node): Node | null {
    if (originalRoot === targetNode) {
        return clonedRoot;
    }

    const originalChildren = originalRoot.childNodes;
    const clonedChildren = clonedRoot.childNodes;

    for (let i = 0; i < originalChildren.length; i++) {
        const foundNode = findCorrespondingNode(originalChildren[i], clonedChildren[i], targetNode);
        if (foundNode) {
            return foundNode;
        }
    }
    return null;
}
