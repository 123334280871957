import React from "react";
import { ComponentData, ComponentDef, ComponentPropDef } from "../types";
import { EditAreaEditable } from "./EditAreaEditable";
import { EditAreaComponent } from "./EditAreaComponent";

type EditAreaTagProps = {
    componentDef: ComponentDef;
    component: ComponentData
    templateElement: Element,
};

export const EditAreaTag: React.FC<EditAreaTagProps> = ({ componentDef, component, templateElement: element }) => {
    const attributes = Array.from(element.attributes).reduce((acc, attr) => {
        // Replace {name} with actual values
        attr.value = attr.value.replace(/{(.*?)}/g, (match, propName) => {
            const propDef = componentDef.props.find((prop) => prop.name === propName);
            if (propDef) {
                const value = component.props[propDef.name];
                return value;
            }
            return match;
        });
        acc[attr.name] = attr.value;
        return acc;
    }, {} as Record<string, any>);

    if (isVoidElement(element as HTMLElement)) {
        return React.createElement(element.tagName.toLowerCase() as keyof JSX.IntrinsicElements,
            {
                ...attributes,
            });
    }

    return React.createElement(
        element.tagName.toLowerCase() as keyof JSX.IntrinsicElements,
        {
            ...attributes
        },
        element.childNodes && Array.from(element.childNodes).map((node: Node, index: number) => {

            // Handle text nodes
            if (node.nodeType === Node.TEXT_NODE) {
                const propDef = getPropertyDefFromNode(componentDef, node);
                if (propDef) {
                    if (propDef?.type === 'text') {
                        // Replace placeholders with actual values
                        const value = component?.props[propDef.name];
                        return value;
                    }

                    if (propDef?.type === 'group') {
                        const innerComponents = component?.props[propDef.name] as ComponentData[];
                        const allowedGroupChildren = propDef.children;
                        if (innerComponents?.length >= 0) {
                            return innerComponents.map((innerComponent) => (
                                <EditAreaComponent key={innerComponent.guid} component={innerComponent} allowedGroupChildren={allowedGroupChildren} />
                            ));
                        }
                        else {
                            // TODO
                            return <div>innerComponents not found</div>;
                        }
                    }
                }
                else {
                    // return the text content
                    return node.textContent;
                }
            }

            if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as Element;
                return element.hasAttribute("contenteditable") ?
                    <EditAreaEditable componentDef={componentDef} component={component} templateElement={element} /> :
                    <EditAreaTag componentDef={componentDef} component={component} templateElement={element} />;
            }

            return <div key={index}>EditAreaTag Fallback</div>;
        })
    );
};

function isVoidElement(element: HTMLElement | null): boolean {
    if (!element) return false; // Check for null or undefined

    const voidTags = new Set([
        'IMG', 'INPUT', 'BR', 'HR', 'AREA', 'BASE', 'COL', 'EMBED', 'LINK',
        'META', 'PARAM', 'SOURCE', 'TRACK', 'WBR'
    ]);

    return voidTags.has(element.tagName);
};

function getPropertyDefFromNode(componentDef: ComponentDef, node: Node): ComponentPropDef | null {

    const text = node.textContent!.trim();
    if (!text) return null;

    const propName = text.slice(1, -1);
    const propDef = componentDef.props.find((prop) => prop.name === propName);
    if (!propDef) return null;

    return propDef;
}