import "./DragArea.css";
import { setDragComponentDef } from "./DragDrop";
import { useEditorContext } from "./EditorContext";

const DragArea = () => {
    const { componentDefs, dragAreaGroups, setDragComponentName } = useEditorContext();

    return (
        <div className="drag-area">
            {dragAreaGroups.map(group => (
                <div key={group.group} className="group">
                    <div className="group-header">{group.group}</div>
                    <div className="items">
                        {group.items.map(item => {
                            const componentDef = componentDefs.find(def => def.name === item);
                            if (!componentDef) {
                                return <></>;
                            }

                            return (
                                <div
                                    key={item}
                                    className="item"
                                    draggable
                                    onDragStart={event => {
                                        if (componentDef) {
                                            setDragComponentDef(event, componentDef);
                                            setDragComponentName(componentDef.name);
                                        }
                                    }}
                                >
                                    <div className="item-icon">
                                        {componentDef.icon && <img src={componentDef.icon} alt={componentDef.title} />}
                                    </div>
                                    <div className="item-content">
                                        {componentDef.title}
                                        {componentDef.description && <div>{componentDef.description}</div>}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DragArea;